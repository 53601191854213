<template>
  <div class="main">
    <el-container class="main-content">
      <el-aside :width="isCollapse ? '60px' : '210px'">
        <nav-menu :menus="userMenus" :collapse="isCollapse"></nav-menu>
      </el-aside>
      <el-container class="page">
        <el-header class="page-header">
          <nav-header @foldChange="handleFoldChange"></nav-header>
        </el-header>
        <el-main class="page-content">
          <router-view />
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import navHeader from "@/components/nav-header/nav-header.vue";
import navMenu from "@/components/nav-menu/nav-menu.vue";
import navigation from '@/utils/navigation'
export default {
  data() {
    return {
      isCollapse: false,
      userMenus: [],
    };
  },
  components: {
    navHeader,
    navMenu,
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      const userMsg = localStorage.getItem('userMsg')
      if (!userMsg) return next('/')
      const userMsgObj = JSON.parse(userMsg)
      this.userMenus = navigation(userMsgObj.is_admin, userMsgObj.permission)
    },
    handleFoldChange(isFold) {
      this.isCollapse = isFold;
    },
  },
};
</script>
<style scoped lang="less">
.main {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
}

.main-content,
.page {
  height: 100%;
}

.page-content {
  height: calc(100% - 48px);
}

.el-header,
.el-footer {
  display: flex;
  color: #333;
  text-align: center;
  align-items: center;
}

.el-header {
  height: 48px !important;
}

.el-aside {
  overflow-x: hidden;
  overflow-y: auto;
  line-height: 200px;
  text-align: left;
  cursor: pointer;
  background-color: #001529;
  transition: width 0.3s linear;
  scrollbar-width: none; /* firefox */
  -ms-overflow-style: none; /* IE 10+ */

  &::-webkit-scrollbar {
    display: none;
  }
}

.el-main {
  color: #333;
  // text-align: center;
  background-color: #f0f2f5;
}
</style>
