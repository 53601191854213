<template>
  <div class="nav-menu">
    <div class="logo">
      <img class="img" src="~@/assets/logo.png" alt="" />
      <span class="title" v-if="!collapse">汉博利特</span>
    </div>
    <el-menu
      :default-active="defaultValue"
      class="el-menu-vertical"
      :collapse="collapse"
      background-color="#0c2135"
      text-color="#b7bdc3"
      active-text-color="#0a60bd"
    >
      <template v-for="item in menus">
        <!-- 二级菜单 -->
        <template v-if="item.children">
          <!-- 二级菜单的可以展开的标题 -->
          <template v-if="!collapse">
            <el-submenu :index="item.id" :key="item.id">
              <template #title>
                <i v-if="item.icon" :class="item.icon" class="iconfont"></i>
                <span>{{ item.title }}</span>
              </template>
              <!-- 遍历里面的item -->
              <template>
                <el-menu-item v-for="subitem in item.children" :index="subitem.id.toString()" :key="subitem.id">
                  <i v-if="subitem.icon" :class="subitem.icon" class="iconfont"></i>
                  <span>{{ subitem.title }}</span>
                </el-menu-item>
              </template>
            </el-submenu>
          </template>
          <template v-else>
            <div :key="item.id" class="collapseFalse">
              <el-menu-item v-for="item1 in item.children" :index="item1.id.toString()" :key="item1.id">
                <i class="iconfont" :class="item1.icon"></i>
                <template #title
                  ><span>{{ item1.title }}</span></template
                >
              </el-menu-item>
            </div>
          </template>
        </template>
        <!-- 一级菜单 -->
        <template v-else>
          <el-menu-item :index="item.id.toString()" :key="item.id" @click="changeRoute(item)">
            <i v-if="item.icon" :class="item.icon" class="iconfont"></i>
            <span>{{ item.title }}</span>
          </el-menu-item>
        </template>
      </template>
    </el-menu>
    <div style="display: none">{{ setDefaultValue }}</div>
  </div>
</template>

<script>
export default {
  props: {
    collapse: {
      type: Boolean,
      default: false
    },
    menus: Array
  },
  data() {
    return {
      defaultValue: ''
    }
  },
  methods: {
    changeRoute(data) {
      if (data.path) this.$router.push(data.path)
    }
  },
  computed: {
    setDefaultValue() {
      let route = this.$route
      this.menus.forEach((item) => {
        if (item.path == route.path) {
          this.defaultValue = item.id + ''
        }
      })
      return ''
    }
  }
}
</script>

<style scoped lang="less">
.nav-menu {
  height: 100%;
  background-color: #001529;

  .logo {
    display: flex;
    height: 28px;
    padding: 12px 10px 8px 10px;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .img {
      height: 75%;
      margin: 0 10px 0 5px;
    }

    .title {
      font-size: 16px;
      font-weight: 700;
      color: white;
      white-space: nowrap;
    }
  }

  .el-menu {
    border-right: none;
    .collapseFalse {
      padding: 10px 0;
      background-color: #001529;
      // border-bottom: 1px solid #fff;
      // &:first-child {
      // border-top: 1px solid #fff;
      // }
    }
  }

  // 目录
  .el-submenu {
    background-color: #001529 !important;
    // 二级菜单 ( 默认背景 )
    .el-menu-item {
      // padding-left: 50px !important;
      background-color: #0c2135 !important;
      // font-size: 16px;
    }
  }

  ::v-deep .el-submenu__title {
    background-color: #001529 !important;
  }

  // hover 高亮
  .el-menu-item:hover {
    color: #fff !important; // 菜单
    i {
      color: #fff;
    }
  }

  .el-menu-item.is-active {
    color: #fff !important;
    background-color: #0a60bd !important;
  }
}

.el-menu-vertical:not(.el-menu--collapse) {
  width: 100%;
  height: calc(100% - 48px);
}
.iconfont {
  font-size: 18px;
  margin-right: 15px;
}
.el-menu-item {
  height: 40px;
  line-height: 40px;
  background-color: #001529 !important;
}
</style>
