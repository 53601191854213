<template>
  <div class="nav-header">
    <div>
      <i class="fold-menu" :class="isFold ? 'el-icon-s-fold' : 'el-icon-s-unfold'" @click="handleFoldClick"></i>
    </div>
    <el-dropdown v-if="isShow" placement="top" @command="handleExitClick">
      <span class="el-dropdown-link"> {{ name }}<i class="el-icon-arrow-down el-icon--right"></i> </span>
      <template #dropdown>
        <el-dropdown-menu>
          <el-dropdown-item icon="el-icon-circle-close" command>退出登录</el-dropdown-item>
        </el-dropdown-menu>
      </template>
    </el-dropdown>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isFold: false,
      name: 'admin',
      isShow: false
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      const token = localStorage.getItem('token')
      if (token) {
        const userMsg = localStorage.getItem('userMsg') || null
        if (userMsg) {
          this.name = JSON.parse(userMsg).username
          this.isShow = true
        }
      }
    },
    handleFoldClick() {
      this.isFold = !this.isFold
      this.$emit('foldChange', this.isFold)
    },
    handleExitClick() {
      window.localStorage.removeItem('token')
      // this.$router.push('/login')
      location.href = '/'
    }
  }
}
</script>

<style scoped lang="less">
.nav-header {
  width: 100%;
  padding-right: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .fold-menu {
    font-size: 30px;
    cursor: pointer;
  }
}
.el-dropdown-link {
  cursor: pointer;
  display: flex;
  align-items: center;
}
</style>
